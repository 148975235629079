<template>
    <el-card>
        <div class="page-sub-title">
            筛选查询
        </div>
        <div class="search-item">
            <span class="label">酒店名称</span>
            <el-input v-model="searchParams.hotelName" clearable size="small" style="width: 400px"
                placeholder="请输入酒店名称"></el-input>
        </div>
        <div class="search-item">
            <span class="label">充值单号</span>
            <el-input v-model.trim="searchParams.orderNum" clearable size="small" style="width: 200px" placeholder="请输入充值单号"
                @input="handleInput"></el-input>
        </div>
        <div class="search-item">
            <span class="label">变更类型</span>
            <el-select v-model="searchParams.type" clearable placeholder="请选择" size="small" style="width: 100px"
                @clear="typeClear">
                <el-option v-for="item in typeList" :key="item.type" :label="item.typeName" :value="item.type">
                </el-option>
            </el-select>
        </div>
        <div class="search-item">
            <span class="label">开票状态</span>
            <el-select v-model="searchParams.invoiceStatus" clearable placeholder="请选择" size="small" style="width: 120px">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="item in invoiceList" :key="item.invoiceStatus" :label="item.invoiceName"
                    :value="item.invoiceStatus">
                </el-option>
            </el-select>
        </div>
        <div class="search-item">
            <span class="label">支付成功时间</span>
            <el-date-picker v-model="searchDate" size="small" style="width:370px" type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                clearable>
            </el-date-picker>
        </div>
        <div class="search-item">
            <el-button type="primary" size="small" @click="searchList">查询</el-button>
            <el-button class="border-btn" plain size="small" @click="clear">重置</el-button>

            <el-tooltip class="item" content="您没有权限操作，请联系管理员" placement="right" :disabled="tooltipDisabled">
                <el-button type="primary"  size="small" @click="printToExcel"
                    :class="{ 'disabled': buttonDisabled }">导出</el-button>
            </el-tooltip>
        </div>
        <div class="page-sub-title">
            短信充值记录
        </div>
        <el-table :data="list" style="width: 100%" ref="rechargeTable" border header-row-class-name="table-header"
            row-key="id" id="out-table" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align="center" :reserve-selection="true" width="55">
            </el-table-column>
            <el-table-column align="center" prop="hotelName" label="酒店名称" width="250px"></el-table-column>
            <el-table-column align="center" prop="typeName" label="变更类型">
            </el-table-column>
            <el-table-column align="center" prop="smsRechargePackageName" label="充值短信套餐">
            </el-table-column>
            <el-table-column align="center" prop="payamount" label="支付金额(元)">
            </el-table-column>
            <el-table-column align="center" prop="id" label="充值单号" width="250px"></el-table-column>
            <el-table-column align="center" prop="payTime" label="支付成功时间" width="250px"></el-table-column>
            <el-table-column align="center" prop="invoiceName" label="开票状态">
            </el-table-column>
        </el-table>
        <el-pagination background class="text-right m-t-16" :page-sizes="pageSizes" :current-page="searchParams.pageNo"
            :page-size="searchParams.size" layout="total, sizes, prev, pager, next, jumper" :total="totalItems"
            @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
    </el-card>
</template>

<script>
import { check_config_key } from '@/api/common'
import { get_recharge_record, download_recharge_record } from '@/api/finance.js'
export default {
    name: 'finance-rechargeRecord',
    data() {
        return {
            pageSizes: [10, 20, 50, 100, 200],
            totalItems: 0,
            searchParams: {
                orderNum: "",    //充值编号
                hotelName: "",    //酒店名称
                invoiceStatus: "",  //开票状态
                type: 1,         //变更类型
                startTime: "",   //支付起始时间
                endTime: '',    //支付截止时间
                pageNo: 1,
                size: 20,
            },
            // 导出参数
            exportParams: {
                orderNumList: '',
                hotelName: "",
                invoiceStatus: "",
                type: 1,
                startTime: '',
                endTime: '',
                orderNum: ''
            },
            searchDate: [],
            list: [],
            typeList: [
                {
                    type: '',
                    typeName: '全部'
                },
                {
                    type: 1,
                    typeName: '线上充值'
                },

                {
                    type: 2,
                    typeName: '线下充值'
                },
                {
                    type: 3,
                    typeName: '开户赠送'
                },
                {
                    type: 4,
                    typeName: '转移'
                },
                {
                    type: 5,
                    typeName: '修改'
                },
            ],
            invoiceList: [
                {
                    invoiceStatus: 1,
                    invoiceName: '待申请开票',
                },
                {
                    invoiceStatus: 2,
                    invoiceName: '已开票',
                },
                {
                    invoiceStatus: 3,
                    invoiceName: '无法开票',
                },
                {
                    invoiceStatus: 4,
                    invoiceName: '已申请开票',
                },
            ],
            orderNumList: '',
            auth: []
        }
    },
    methods: {
        handleInput() {
            this.searchParams.orderNum = this.searchParams.orderNum.replace(/[^\d]/g, '');
        },
        // 获取权限
        getAuth() {
            check_config_key({
                configKeyList: ['finance:rechargeRecord:btn'],
                hotelId: 0
            }).then(res => {
                this.auth = res && res.filter(o => { return !!o.hasConfigKey })
                    .map(v => { return v.configKey })
            })
        },
        // 导出表格
        async printToExcel() {
            if (this.buttonDisabled) return
            this.exportParams.orderNumList = this.orderNumList
            try {
                let res = await download_recharge_record(this.exportParams.orderNumList, this.exportParams.hotelName, this.exportParams.invoiceStatus, this.exportParams.type, this.exportParams.startTime, this.exportParams.endTime, this.exportParams.orderNum)
                if (res) {
                    window.location.href = res
                } else {
                    this.$message.error('列表数据为空 无法导出')
                }
            } catch (error) {
                this.$message.error(error.msg);
            }
        },
        // 获取短信充值记录
        async getRechargeRecord(pages = 1) {
            this.searchParams.pageNo = pages
            try {
                let res = await get_recharge_record(this.searchParams)
                if (res != null) {
                    this.totalItems = res.total ? res.total : 0
                    let arr = this.$deepClone(res.records ? res.records : '')
                    if (arr) {
                        arr.forEach(item => {
                            this.typeList.forEach(i2 => {
                                if (item.type == i2.type) {
                                    item.typeName = i2.typeName
                                }
                            })
                            this.invoiceList.forEach(i3 => {
                                if (item.invoiceStatus == i3.invoiceStatus) {
                                    item.invoiceName = i3.invoiceName
                                }
                            })
                        })
                    }
                    this.list = arr || []
                } else {
                    this.list = []
                }
            } catch (error) {
                this.$message.error(error);
            }

        },
        handleSizeChange(val) {
            this.searchParams.size = val
            this.getRechargeRecord()
        },
        handleCurrentChange(val) {
            this.getRechargeRecord(val)
        },
        // 清空查询参数
        clear() {
            this.searchDate = []
            Object.assign(this.searchParams, {
                orderNum: "",
                hotelName: "",
                invoiceStatus: "",
                type: 1,
                startTime: "",
                endTime: "",
                pageNo: 1,
                size: 20,
            })
            this.$refs.rechargeTable.clearSelection()
            this.getRechargeRecord()
            this.checkParams()
        },
        searchList() {
            this.checkParams()
            this.$refs.rechargeTable.clearSelection()
            this.getRechargeRecord()
        },
        handleSelectionChange(val) {
            this.orderNumList = val.map(obj => obj.id).join(',');
        },
        // 处理参数
        checkParams() {
            this.exportParams.orderNumList = this.orderNumList
            let params = this.$deepClone(this.searchParams)
            this.exportParams.hotelName = params.hotelName
            this.exportParams.invoiceStatus = params.invoiceStatus
            this.exportParams.type = params.type
            this.exportParams.startTime = params.startTime
            this.exportParams.endTime = params.endTime
            this.exportParams.orderNum = params.orderNum
        },
        typeClear() {
            this.searchParams.type = ''
        }
    },
    mounted() {
        this.getRechargeRecord()
        this.getAuth()
    },
    watch: {
        searchDate: {
            deep: true,
            handler(newDate) {
                if (newDate != null) {
                    this.searchParams.startTime = newDate[0]
                    this.searchParams.endTime = newDate[1]
                } else {
                    this.searchParams.startTime = ''
                    this.searchParams.endTime = ''
                }
            }
        }
    },
    computed: {
        tooltipDisabled() {
            return this.auth.indexOf('finance:rechargeRecord:btn') >= 0;
        },
        buttonDisabled() {
            return this.auth.indexOf('finance:rechargeRecord:btn') < 0;
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .disabled {
    opacity: 0.6;
    cursor: no-drop;

    &.el-dropdown-menu__item:hover {
        .icon-login {
            background: url('../../assets/icons/icon-login1.png');
        }
    }
}

.recharge-input {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
}
</style>